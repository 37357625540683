<template>
  <auth-layout backToLogin>
    <div class="card-body login-card-body pl-0">
      <form @submit.prevent="submit" method="post">
        <div class="form-group">
          <label class="col-form-label" for="inputEmail">Email</label>
          <input
            type="text"
            :class="errorRequest ? 'form-control is-invalid' : 'form-control login-focus'"
            autocomplete="off"
            v-model="form.username"
            placeholder="Email"
            id="inputEmail"
            :disabled="loading"
            @blur="checkEmail"
          />
          <span v-if="msg !== ''" class="text-red">{{ msg }}</span>
          <!-- <span v-else class="text-red">
            {{ this.errorRequest }}
          </span>  -->
        </div>
        <div class="row">
          <div class="col-12">
            <!-- <button type="submit" class="btn btn-primary btn-block">Request new password</button> -->
            <button
              type="submit"
              class="btn vm-btn-submit btn-block btn-flat"
              :disabled="loading"
            >
              <i class="fas fa-spinner fa-spin" v-if="loading"></i>
              {{ loading ? "..." : "Kirim" }}
            </button>
          </div>
          <!-- /.col -->
        </div>
      </form>
    </div>
    <!-- /.login-card-body -->
  </auth-layout>
</template>

<script>
const { console } = window;
import AuthLayout from "@components/layouts/Auth.vue";

export default {
  components: {
    AuthLayout,
  },
  data: () => ({
    prevRoute: {
      name: "login",
    },
    form: {
      username: "",
    },
    formreset: {
      otp: null,
      password: null,
    },
    error: undefined,
    errorRequest: undefined,
    msg: "",
    loading: false,
    regexEmail:/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
  }),
  mounted() {
    $("body").attr("class", "hold-transition vm-login-page");
    $("body,html").removeAttr("style");
    $("body").Layout("fixLayoutHeight");
  },
  methods: {
    async submit() {
      this.loading = true;
     
      try {
        //post email forgot password
        await this.Api.forgot("/forgot_password/request", this.form)
        this.$router.replace({path: '/success-sent-email'})
        this.loading = false;        
      } catch (error) {
        this.loading = false
        //message error validation if click submit
        if(this.form.username === "") {
          this.loading = false;
          this.msg = 'Email wajib diisi.'
        } else if(!this.regexEmail.test(this.form.username)) {
          this.loading = false;
          this.msg = "Format email tidak sesuai."
        } else {
          this.loading = false;
          this.msg = error.response.data.message
        }
      }
    },
    checkEmail(e) {
      //message error validation if onchange
      if(e.target.value) {
        if(this.regexEmail.test(e.target.value)) {
          this.msg = '';
        } else {
          this.msg = 'Format email tidak sesuai.';
        }
      } else {
        this.msg = 'Email wajib diisi.'
      }
    },
  },
};
</script>

<style>
.login-banner {
  width: 100px;
  margin: auto;
  display: block;
}
.login-page {
  background: #333333 !important;
}
.login-page .login-logo {
  padding-top: 20px;
  margin-bottom: 0px;
}
.login-page .login-logo img {
  background-position: center center;
  width: 26px;
  height: 26px;
  margin-top: -6px;
  margin-right: 6px;
}
</style>
